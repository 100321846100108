<template>
  <source :srcset="srcSet" :media="media" :type="imageType" />
</template>
<script setup lang="ts">
type Resolution = 480 | 800 | 1200;

export type Srcset = `${string} ${Resolution}w` | string;
export type SourceProp = {
  srcset: Srcset;
  media?: string;
  width?: string | null;
  height?: string | null;
  auto?: boolean;
  format: 'avif' | 'webp' | 'jpeg';
  fit?: 'cover' | 'contain' | 'fill';
  sizes?: string | null;
};

const defaultImage = '/default.svg';

const props = withDefaults(defineProps<SourceProp>(), {
  media: '',
  auto: false,
  fit: 'cover',
  sizes: null,
  width: null,
  height: null,
});

console.log(props, 'appSourceProps');

const srcSet = computed(() => {
  const sourceSets = props.srcset.split(',');

  if (sourceSets.length === 0) {
    return `${generateUrl(defaultImage)} 480w`;
  }

  if (sourceSets.length === 1) {
    return generateUrl(sourceSets[0] || defaultImage);
  }

  const srcset = sourceSets.map(src => {
    const [url, size] = src.trim().split(' ');

    if (!size) {
      return `${generateUrl(defaultImage)}  ${url}`;
    }

    return `${generateUrl(url || defaultImage)} ${size}`;
  });

  return srcset.join(', ');
});
const image = useImage();
function generateUrl(url: string) {
  return image(url, {
    ...(props.sizes ? {} : { width: Number(props.width), height: Number(props.height) }),
    fit: props.fit,
    format: props.format,
    enlarge: true,
    background: 'transparent',
    sizes: props.sizes,
  });
}

const imageType = computed(() => {
  if (props.format === 'avif') {
    return 'image/avif';
  }

  if (props.format === 'webp') {
    return 'image/webp';
  }

  return 'image/jpeg';
});
</script>
